<template>
  <section id="openingHours" class="py-4">
    <div class="timer-list" style="margin-top: 0">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('monday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.monday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.monday"
        v-if="hasRanges('monday')"
        id="monday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.monday" /> -->
      <DayClosedLabel v-if="isHoliday('monday')" />
    </div>

    <ElessarSlider
      id="monday"
      :values="openingHours.monday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('tuesday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.tuesday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.tuesday"
        v-if="hasRanges('tuesday')"
        id="tuesday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.tuesday" /> -->
      <DayClosedLabel v-if="isHoliday('tuesday')" />
    </div>

    <ElessarSlider
      id="tuesday"
      :values="openingHours.tuesday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('wednesday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.wednesday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.wednesday"
        v-if="hasRanges('wednesday')"
        id="wednesday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.wednesday" /> -->
      <DayClosedLabel v-if="isHoliday('wednesday')" />
    </div>

    <ElessarSlider
      id="wednesday"
      :values="openingHours.wednesday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('thursday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.thursday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.thursday"
        v-if="hasRanges('thursday')"
        id="thursday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.thursday" /> -->
      <DayClosedLabel v-if="isHoliday('thursday')" />
    </div>

    <ElessarSlider
      id="thursday"
      :values="openingHours.thursday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('friday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.friday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.friday"
        v-if="hasRanges('friday')"
        id="friday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.friday" /> -->
      <DayClosedLabel v-if="isHoliday('friday')" />
    </div>

    <ElessarSlider
      id="friday"
      :values="openingHours.friday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('saturday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.saturday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.saturday"
        v-if="hasRanges('saturday')"
        id="saturday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.saturday" /> -->
      <DayClosedLabel v-if="isHoliday('saturday')" />
    </div>

    <ElessarSlider
      id="saturday"
      :values="openingHours.saturday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('sunday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.sunday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.sunday"
        v-if="hasRanges('sunday')"
        id="sunday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.sunday" /> -->
      <DayClosedLabel v-if="isHoliday('sunday')" />
    </div>

    <ElessarSlider
      id="sunday"
      :values="openingHours.sunday"
      @changeRange="changeRange($event)"
    />
  </section>
</template>

<script setup>
/* eslint-disable */

import lodash from "lodash";
import ElessarSlider from "./elessar-slider";
import OpeningHoursLabel from "./OpeningHoursLabel";
import DayClosedLabel from "./DayClosedLabel";
// import FullDayLabel from './FullDayLabel'
import { ref, provide, watchEffect, watch } from "vue";

const props = defineProps({
  department: {
    required: true,
    type: [Object, Array],
  },
});

const emit = defineEmits(["update:department"]);

const openingHours = ref(lodash.cloneDeep(props.department.openingHours));
const deletedRangeIndex = ref(null);

const deleteRange = async ({ day, index }) => {
  await setDeleteRange(index);
  openingHours.value[day] = openingHours.value[day].filter(
    (day, i) => i != index
  );
};

const setDeleteRange = (index) => {
  deletedRangeIndex.value = index;
  return Promise.resolve();
};

const changeRange = ({ day, ranges }) => {
  openingHours.value[day] = ranges;
};

const isHoliday = (day) => {
  return !openingHours.value?.[day]?.length;
};

const hasRanges = (day) => {
  return openingHours.value?.[day]?.length;
};

const updateDepartmentValue = () => {
  const editedDepartment = lodash.cloneDeep(props.department);
  editedDepartment.openingHours = openingHours.value;
  emit("update:department", editedDepartment);
};

watch(
  openingHours,
  () => {
    updateDepartmentValue();
  },
  {
    deep: true,
  }
);

provide("deleteRange", deleteRange);
provide("deletedRangeIndex", deletedRangeIndex);
provide("setDeleteRange", setDeleteRange);
</script>
